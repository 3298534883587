<template>
  <div>
    <compact-list :payload="compactListPayload" :total="total"></compact-list>
    <v-dialog v-if="createDialog" v-model="dialog" persistent no-click-animation>
      <!-- <note-pad :notesInfo="notesInfo" :fromApp="fromApp" :accesscontrol="accesscontrol" ref="notepad"></note-pad> -->
      <note-pad v-model="notesInfo" :fromApp="fromApp" :accesscontrol="accesscontrol" ref="notepad"></note-pad>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: ['accesscontrol'],
  data () {
    return {
      dialog: false,
      loading: false,
      createDialog: false,
      notesTitle: '',
      selected: [],
      moduleObj: {
        moduleId: this.$route.params.id,
        moduleName: this.$route.params.name,
        recordId: this.$route.params.record_id
      },
      notesInfo: {},
      MODULEURL: 'notes',
      fromApp: false,
      compactListPayload: {
        showBackBtn: () => this.$parent.removeActiveModule(),
        hideHeader: false,
        addClickHandler: this.openCreateDialogHandler,
        editHandler: () => {},
        refreshHandler: this.getNoteListHandler,
        hasNoNext: false,
        loading: false,
        list: [],
        completeList: [],
        pagination: {},
        storeIndex: '',
        items: [],
        hasFilter: false,
        isCustomModule: false,
        isNotes: true,
        moduleIcon: 'mdi-note',
        hasPermission: false,
        accesscontrol: this.accesscontrol
      },
      pagination: { itemsPerPage: 8, page: 1 },
      total: 0
    }
  },
  computed: {
    ...mapGetters(['listOfModules', 'userDetails']),
    headers () {
      return [{
        text: this.$t('title'),
        value: 'title',
        width: '20%'
      }, /* {
        text: 'Content',
        value: 'content',
        width: '60%'
      },  */{
        text: this.$t('modifiedOn'),
        value: 'modified_at',
        width: '20%'
      }]
    }
  },
  components: {
    'note-pad': () => import('@/components/notes/NotePad'),
    'compact-list': () => import('@/components/CompactList')
  },
  created () {
    this.getNoteListHandler()
  },
  watch: {
    '$store.state.common.listOfModules' (val) {
      this.setPermission()
    }
  },
  mounted () {
    this.setPermission()
    const text = 'deleteMsg'
    this.$eventBus.$on('closeNotePad', (data) => {
      this.closeDialogHandler()
    })
    this.$eventBus.$on('loadList', () => {
      this.getNoteListHandler()
    })
    // this.$eventBus.$on('deleteItems', (data) => {
    //   if (data.module === this.MODULEURL) {
    //     const payload = { ids: [data.id], url: 'notes/delete_multiple', module: this.MODULEURL }
    //     this.$store.commit('showDeleteDialog', payload)
    //   }
    // })
    this.$eventBus.$on('deleteItems', (data) => {
      const payload = { ids: data, url: 'notes/delete_multiple', module: this.MODULEURL }
      this.$store.commit('showDeleteDialog', payload)
    })
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === this.MODULEURL) {
        this.selected = []
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
        this.$store.commit('hideDialog')
        this.getNoteListHandler()
      }
    })
    this.compactListPayload.module = this.MODULEURL
  },
  methods: {
    getNoteListHandler () {
      this.compactListPayload.loading = true
      this.$api.execute('get', `${this.MODULEURL}?relation_name=${this.moduleObj.moduleName}&relation_id=${this.moduleObj.recordId}`).then(response => {
        this.total = response.data.length
        response.data.forEach(element => {
          element.modified_at = this.$formatter.formatDate(element.modified_at, 'DD.MM.YYYYTHH:mm:ss', `${this.userDetails.dateformat} HH:mm:ss`)
          if (element.content) {
            const a = element.content
            const d = document.createElement('div')
            d.innerHTML = a
            element.content = d.innerText
            element.isNotes = true
          }
        })
        this.compactListPayload.list = response.data
        // this.compactListPayload.completeList = response.data
        // this.$eventBus.$emit('changePage', 1)
        this.compactListPayload.items = this.headers
      }).finally(() => {
        this.compactListPayload.loading = false
      })
    },
    openDialogHanlder () {
      this.dialog = true
    },
    closeCreateDialogHandler () {
      this.createDialog = false
    },
    openCreateDialogHandler () {
      this.notesInfo = { title: this.notesTitle, relation_name: this.moduleObj.moduleName, relation_id: this.moduleObj.recordId, type: 'add' }
      this.dialog = true
      this.createDialog = true
    },
    closeDialogHandler () {
      this.dialog = false
    },
    addorUpdateNotes (type) {
      let model = {}
      if (type === 'add') {
        model = { title: this.notesTitle, relation_name: this.moduleObj.moduleName, relation_id: this.moduleObj.recordId }
      }
      this.$api.saveUpdateHandler(this.MODULEURL, model).then(response => {
        this.notesInfo = response
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'savedSuccess' })
        this.getNoteListHandler()
        this.createDialog = false
        this.$refs.notesForm.reset()
        this.dialog = true
      })
    },
    getNotesHandler ({ _id, accesscontrol }) {
      this.$api.execute('get', `${this.MODULEURL}/${_id}`).then(response => {
        this.notesInfo = response.data
        this.dialog = true
        this.createDialog = true
      })
    },
    deleteHandler () {
      const ids = this.selected.map(x => x._id)
      const data = { module: this.MODULEURL, ids }
      this.$eventBus.$emit('deleteItems', data)
    },
    setPermission () {
      // const hasManagePermission = this.$formatter.permissionSetting(this.listOfModules, this.moduleObj.moduleId, this.userDetails)
      this.compactListPayload.hasPermission = true
      // this.compactListPayload.editHandler = this.getNotesHandler // hasManagePermission ? this.getNotesHandler : () => {}
      this.compactListPayload.editHandler = this.accesscontrol.view ? this.getNotesHandler : () => {}
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('deleteItems')
    this.$eventBus.$off('deleteSuccess')
    this.$eventBus.$off('loadList')
  }
}
</script>
<style scoped>
.overflow-td {
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.checkboxPadding .v-input--selection-controls {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.adjuting .ck.ck-toolbar {
  padding: 3px !important;
}
</style>
